<template>
  <div class="gps-cars">
    <view-grid
      :tableCols="tableCols"
      :tableOps="tableOps"
      :query="query"
      :tableData="tableData"
      :total="total"
      :searchCols="searchCols"
      :selection="true"
      @callbackEvent="callbackEvent"
      @handleCurrentChange="handleCurrentChange"
    ></view-grid>
    <component
      @saveEvent="saveEvent"
      ref="refAnyCom"
      :formArray="formArray"
      :is="currentCopmonent"
      :row="curr_row"
      :dialogTitle="t('cars.carTitle')"
    />
  </div>
</template>

<script setup>
import ViewGrid from "./components/ViewGrid.vue";
import moment from "moment";
import refresh_table from "../../utils/refrshTable";
import {
  ref,
  defineAsyncComponent,
  watch,
  getCurrentInstance,
  shallowRef,
  reactive,
  onMounted,
  toRaw,
  // markRaw,
  // toRef,
} from "vue";
// import {
//   Plus,
//   Delete,
//   BottomLeft,
//   TopRight,
//   Search,
//   Download,
//   Edit,
// } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import cars from "./CarsData";
import { ElNotification, ElMessageBox } from "element-plus";
const { t } = useI18n();
const refAnyCom = ref();
let { proxy } = getCurrentInstance();

//动态组件
const activeKey = ref("GpsForm");
let currentCopmonent = shallowRef({});
currentCopmonent.value = defineAsyncComponent(() =>
  import(`./components/${activeKey.value}.vue`)
);
watch(
  () => activeKey.value,
  () => {
    currentCopmonent.value = defineAsyncComponent(() =>
      import(`./components/${activeKey.value}.vue`)
    );
  }
);

//表格显示的列
const tableCols = ref(cars.tableCols);
//表格操作列
const tableOps = ref(cars.tableOps);
//表单操作
const formArray = reactive(cars.formCols);
const row = {};
const curr_row = reactive(row);

const total = ref(0);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  query.page = val;
  getList();
};

//查询列
const searchCols = reactive(cars.searchCols);

const tableData = ref([]);
const query = reactive({ rp: 25, page: 1 });

const getList = () => {
  proxy.$api.sys_vehicles_grid(query).then((res) => {
    tableData.value = res.data.data;
    total.value = res.data.pagination.totalRecords;
    if (res.data.is_op == 1) {
      //
      tableOps.value.splice(1, 1);
      searchCols.splice(4, 4);
    }
  });
  proxy.$api.item_get_company({}).then((res) => {
    let ay = res.data.data.map((it) => {
      it.name = it.cName;
      it.value = it.id;
      return it;
    });
    formArray[2].data = ay;
  });
};
const init = () => {
  proxy.$api.sys_get_bases({ flag: ["cllx", "sblx"] }).then((res) => {
    formArray[1].data = res.data.cllx;
    formArray[8].data = res.data.sblx;
  });
  let ay_channel = [];
  for (let i = 0; i < 17; i++) {
    ay_channel.push({
      name: i.toString(),
      value: i.toString(),
    });
  }
  formArray[10].data = ay_channel;
  //查询初始化
  proxy.$api.sys_init_datas({}).then((res) => {
    if (res.data.companys.length > 0) {
      let ay_companys = res.data.companys.map((it) => {
        return {
          name: it.cName,
          value: it.id,
        };
      });
      // console.log(searchCols);
      searchCols[0].data = ay_companys;
    }
  });
};

onMounted(() => {
  getList();
  init();
});

const multipleSelection = ref([]);

const callbackEvent = (obj) => {
  switch (obj.type) {
    case "delete":
      if (multipleSelection.value.length == 0) {
        ElNotification({
          title: t("commKey.MSG_TITLE"),
          message: t("commKey.QXZSCX"),
          duration: 2000,
        });
      } else {
        ElMessageBox.confirm(t("commKey.CONFIRMTITLE"), "Warning", {
          confirmButtonText: t("commKey.Confirm"),
          cancelButtonText: t("commKey.Cancel"),
          type: "warning",
        }).then(() => {
          let ids = multipleSelection.value.map((it) => {
            return it.id;
          });
          // console.log(ids, "sss");
          proxy.$api
            .sys_vehicles_delete({ ids: ids.join(","), t: 1 })
            .then((res) => {
              if (res.data.result == 1) {
                // proxy.$api
                //   .sys_orderdata_save({ ids: obj.row.id, t: 1 })
                //   .then((res_res) => {});
                ElNotification({
                  title: t("commKey.MSG_TITLE"),
                  message: t("commKey.DELETESUCCESS"),
                  duration: 2000,
                });
              } else {
                ElNotification({
                  title: t("commKey.MSG_TITLE"),
                  message: t("commKey.DELETEFAIL"),
                  duration: 2000,
                });
              }
              getList();
            });
        });
      }

      refresh_table("gps_vehicles,gps_devices,gps_sims", 5);
      break;
    case "multipleSelection":
      multipleSelection.value = obj.rows;
      // console.log("multipleSelection", obj.rows);
      break;
    case "add":
    case "edit":
      curr_row.vStopServDate = moment().add(50, "years").format("YYYY-MM-DD");
      console.log(curr_row);
      if (obj.row) {
        Object.assign(curr_row, obj.row);
      }
      // activeShow.value = true;
      activeKey.value = "GpsForm";
      refAnyCom.value.dialogVisible = true;
      break;
    case "delete_one":
      // console.log("sss", obj);
      proxy.$api.sys_vehicles_delete({ ids: obj.row.id, t: 1 }).then((res) => {
        if (res.data.result == 1) {
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETESUCCESS"),
            duration: 2000,
          });
        } else {
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETEFAIL"),
            duration: 2000,
          });
        }
        refresh_table("gps_vehicles,gps_devices,gps_sims", 5);
        getList();
      });
      break;

    case "search":
      Object.assign(query, obj.search);
      getList();
      break;
    case "export":
      Object.assign(query, obj.search);
      proxy.$api.sys_vehicles_grid_excel(query).then((res) => {
        // 处理返回的文件流
        const content = res.data;
        const blob = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        var date =
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate() +
          "" +
          new Date().getHours() +
          "_" +
          new Date().getMinutes() +
          "_" +
          new Date().getSeconds();
        const fileName = t("map.item_info") + "-" + date + "." + "xlsx";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        // console.log(res);
      });
      break;
    case "download":
      location.href = process.env.VUE_APP_BASE_URL + "Public/car.xlsx";
      break;
    case "groups":
      proxy.$api
        .search_getGroupList({ company_id: obj.company_id })
        .then((res) => {
          if (res.data.data.length > 0) {
            let ay = res.data.data.map((it) => {
              return {
                name: it.gName,
                value: it.id,
              };
            });
            searchCols[1].data = ay;
          }
        });
      break;
    case "upload":
      // console.log(obj);
      ElNotification({
        title: t("commKey.MSG_TITLE"),
        message: obj.msg,
        duration: 2000,
      });

      // if (obj.result == 1) {
      //   ElNotification({
      //     title: t("commKey.MSG_TITLE"),
      //     message: t("commKey.IMPORTSUCCESS"),
      //     duration: 2000,
      //   });
      // } else {
      //   ElNotification({
      //     title: t("commKey.MSG_TITLE"),
      //     message: t("commKey.IMPORTFAIL"),
      //     duration: 2000,
      //   });
      // }
      getList();
      break;
  }
};
const saveEvent = (temp) => {
  if (temp.id == 0) {
    temp.vPlateColorID = 0;

    temp.vOwnerName = "";
    temp.vOwnerSex = "男";
    temp.vOwnerAddress = "";
    temp.vOwnerBobyNo = "";
    temp.vOwnerEmail = "";
    temp.vOwnerLinkTel = "";
    temp.vFrameNo = "";
    temp.vEngineNo = "";

    temp.vMore = "";
    temp.vDeviceID = 0;
    temp.vSimID = 0;
  }
  proxy.$api.sys_vehicles_save(temp).then((res) => {
    ElNotification({
      title: t("commKey.MSG_TITLE"),
      message: t("commKey.CAR_" + res.data.msg_type),
      duration: 2000,
    });
    if (res.data.msg_type == 1 || res.data.msg_type == 2) {
      refAnyCom.value.dialogVisible = false;
      getList();
    }
    refresh_table("gps_vehicles,gps_devices,gps_sims", 5);
  });
};
</script>

<style lang="scss" scoped>
// .gps-box {
//   box-sizing: border-box;
//   padding: 10px;
//   .gps-search {
//     display: flex;
//     flex-direction: row;
//     .gps-item {
//       box-sizing: border-box;
//       padding-right: 10px;
//     }
//   }
// }
</style>
